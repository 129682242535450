<template>
  <div class="score">
    <div class="score-content" v-for="(item, index) of scoreList" :key="index">
      <div class="score-content-top" flex="cross:center main:justify">
        <span> {{ item.type == "1" ? "初选" : "复选" }}<span v-if="item.type == '1'">·第{{ item.no }}轮</span></span>
        <!-- 分赛区成绩判断 -->
        <!-- <span><span v-if="item.type == '1'">分赛区·{{ item.no==5 ? '小学': (item.no ==6? '中学': '')}}</span>初选</span> -->
        <span
        >{{
            (new Date(item.endTime*1000).getMonth() +1)+
            "月" +
            new Date(item.endTime*1000).getDate() +
            "日"
          }}&nbsp;&nbsp;
          {{
            formDate(item.startTime*1000)
          }}-{{
            formDate(item.endTime*1000)
          }}</span
        >
      </div>
      <div v-if="item.type == 1" class="score-content-bottom" flex="cross:center main:justify">
        <div flex="cross:baseline">
          总分：<span class="key">{{ item.score }} </span
        >&nbsp;
          <span v-if="item.times < 601"> (含加分5分)</span>
          <span v-else-if="item.times > 600 && item.times < 901"> (含加分3分)</span>
        </div>
        <div>
          用时：<span class="key">{{
            countDown(item.times).split(':')[0]+"'"+ countDown(item.times).split(':')[1]+"''"
          }}</span>
        </div>
      </div>
      <div v-if="item.type == 2" class="score-content-bottom" flex="cross:center main:justify">
        <div flex="cross:baseline">
          总分：<span class="key">{{ item.score }} </span
        >&nbsp;
          <!--          <span v-if="item.times < 601"> (含加分10分)</span>-->
          <!--          <span v-if="item.times > 600 && item.times < 901"> (含加分3分)</span>-->
          <span v-if="item.times < 901"> (含加分3分)</span>
        </div>
        <div>
          用时：<span class="key">{{
            countDown(item.times).split(':')[0]+"'"+ countDown(item.times).split(':')[1]+"''"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataApi from "@/api/apply.js";
import { countDown } from '../../util/tools'
export default {
  data() {
    return {
      scoreList:[],
      countDown:countDown,
    };
  },
  created() {
    this.getScoreList()
  },
  methods: {
    getScoreList() {
      dataApi.getScore().then(res => {
        this.scoreList = res.data
      })
    },
    // 日期格式处理
    formDate(date) {
      let h = new Date(date).getHours()
      let m = new Date(date).getMinutes()
      if(h<10) {
        h = '0' +h
      }
      if(m<10) {
        m = '0' +m
      }
      return h+':'+m
    }
  },
};
</script>

<style scoped="scoped" lang="less">
.score {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background: rgba(#27c190, 0.1);

  &-content {
    width: 6.9rem;
    margin: auto;
    margin-top: 0.32rem;
  }
  &-content-top {
    height: 0.72rem;
    padding: 0 0.24rem;
    font-size: 0.26rem;
    background-image: linear-gradient(to right, #41816d, #42b28e);
    color: #fdfdfd;
    border-top-left-radius: 0.16rem;
    border-top-right-radius: 0.16rem;
  }
  &-content-bottom {
    height: 1.84rem;
    padding: 0 0.24rem;
    font-size: 0.24rem;
    border-bottom-left-radius: 0.16rem;
    border-bottom-right-radius: 0.16rem;
    color: rgba(#999999, 0.7);
    background: #fff;
    .key {
      font-size: 0.48rem;
      color: #330000;
    }
  }
}
</style>
